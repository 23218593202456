import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const TermsText = () => (
  <Row>
    <Col className='terms-text'>
      <p>
        Last updated: January 23, 2024. Please read these terms and conditions
        carefully before using Our Service.
      </p>
      <h2>Interpretation and Definitions</h2>
      <br />
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of these Terms and Conditions:</p>
      <p>
        {' '}
        Affiliate means an entity that controls, is controlled by or is under
        common control with a party, where “control” means ownership of 50% or
        more of the shares, equity interest or other securities entitled to vote
        for election of directors or other managing authority. Account means a
        unique account created for You to access our Service or parts of our
        Service.
      </p>

      <p>Country refers to: United States</p>
      <p>
        Company (referred to as either “the Company“, “We“, “Us“ or “Our“ in
        this Agreement) refers to Bump Boxes, Inc., 7719 North Pioneer Lane,
        Peoria, Illinois 61615. Device means any device that can access the
        Service such as a computer, a cellphone or a digital tablet.
      </p>
      <p>
        Feedback means feedback, innovations or suggestions sent by You
        regarding the attributes, performance or features of our Service.
      </p>
      <p>
        Goods refer to the items offered for sale on the Service, including
        subscription boxes.
      </p>
      <p>Orders mean a request by You to purchase Goods from Us.</p>
      <p>
        Promotions refer to contests, sweepstakes or other promotions offered
        through the Service.
      </p>

      <p>Service refers to the Website.</p>

      <p>
        Subscriptions refer to the services or access to the Service offered on
        a subscription basis by the Company to You.
      </p>

      <p>
        Terms and Conditions (also referred as “Terms“) mean these Terms and
        Conditions that form the entire agreement between You and the Company
        regarding the use of the Service. Third-party Social Media Service means
        any services or content (including data, information, products or
        services) provided by a third-party that may be displayed, included or
        made available by the Service.
      </p>

      <p>Website refers to Bump Boxes, accessible from www.bumpboxes.com.</p>
      <p>
        You means the individual accessing or using the Service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </p>
      <br />

      <h3>Acknowledgement</h3>
      <p>
        These are the Terms and Conditions governing the use of this Service and
        the agreement that operates between You and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the Service.
      </p>

      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        Service.
      </p>

      <p>
        By accessing or using the Service You agree to be bound by these Terms
        and Conditions. If You disagree with any part of these Terms and
        Conditions then You may not access the Service.
      </p>

      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>

      <p>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>
      <br />

      <h3>Placing Orders for Goods</h3>
      <p>
        By placing an Order for Goods through the Service, You warrant that You
        are legally capable of entering into binding contracts.
      </p>

      <h4>Your Information</h4>
      <p>
        If You wish to place an Order for Goods available on the Service, You
        may be asked to supply certain information relevant to Your Order
        including, without limitation, Your name, Your email, Your phone number,
        Your credit card number, the expiration date of Your credit card, Your
        billing address, and Your shipping information.
      </p>
      <p>
        You represent and warrant that: (i) You have the legal right to use any
        credit or debit card(s) or other payment method(s) in connection with
        any Order; and that (ii) the information You supply to us is true,
        correct and complete.
      </p>
      <p>
        By submitting such information, You grant us the right to provide the
        information to payment processing third parties for purposes of
        facilitating the completion of Your Order.
      </p>

      <h4>Order Cancellation</h4>
      <p>
        We reserve the right to refuse or cancel Your Order at any time for
        certain reasons including but not limited to:{' '}
      </p>
      <li>Goods availability</li>
      <li>Errors in the description or prices for Goods </li>
      <li>Errors in Your Order</li>
      <br />
      <p>
        We reserve the right to refuse or cancel Your Order if fraud or an
        unauthorized or illegal transaction is suspected.
      </p>

      <h4>Your Order Cancellation Rights</h4>
      <p>
        Any Goods you purchase can only be returned in accordance with these
        Terms and Conditions.
      </p>
      <p>
        Your right to cancel an Order only applies to Goods that are returned in
        the same condition as You received them. You should also include all of
        the products instructions, documents and wrappings. Goods that are
        damaged or not in the same condition as You received them or which are
        worn simply beyond opening the original packaging will not be refunded.
        You should therefore take reasonable care of the purchased Goods while
        they are in Your possession.
      </p>

      <p>
        To request a refund, You must make a request to Us at
        info@bumpboxes.com. You must request a refund within thirty days of the
        date of shipment. Once your refund request is processed, we will email
        you a return label, at which time you will be required to take the
        return label and Goods to your local FedEx store and ship them back. We
        will reimburse You in a commercially reasonable amount of time after We
        receive the returned Goods. We will use the same means of payment as You
        used for the Order. You will not be refunded shipping and handling costs
        or fees, which are non refundable.
      </p>

      <p>
        You will not have any right to cancel an Order for the supply of any of
        the following Goods:
      </p>
      <li>
        The supply of Goods made to Your specifications or clearly personalized.
      </li>

      <li>
        The supply of Goods which according to their nature are not suitable to
        be returned, have been damaged due to misuse, lack of care, mishandling,
        accident, abuse, or other abnormal use, deteriorate rapidly or where the
        date of expiration is over.
      </li>

      <li>
        The supply of Goods which are not suitable for return due to health
        protection or hygiene reasons and were unsealed after delivery.
      </li>
      <li>
        The supply of Goods which are, after delivery, according to their
        nature, inseparably mixed with other items.
      </li>

      <li>
        The supply of digital content which is not supplied on a tangible medium
        if the performance has begun with Your prior express consent and You
        have acknowledged Your loss of cancellation right.
      </li>

      <li>
        A subscription box or good contained within a subscription box, which We
        have already shipped. Further information concerning subscriptions and
        cancellations of subscriptions is below.
      </li>
      <br />

      <h3>Availability, Errors and Inaccuracies</h3>
      <p>
        We are constantly updating Our offerings of Goods on the Service. The
        Goods available on Our Service may be mispriced, described inaccurately,
        or unavailable, and We may experience delays in updating information
        regarding our Goods on the Service and in Our advertising on other
        websites.
      </p>

      <p>
        We cannot and do not guarantee the accuracy or completeness of any
        information, including prices, product images, specifications,
        availability, and services. We reserve the right to change or update
        information and to correct errors, inaccuracies, or omissions at any
        time without prior notice.
      </p>

      <br />
      <h3>Prices Policy</h3>
      <p>
        The Company reserves the right to revise its prices at any time prior to
        accepting an Order. The prices quoted may be revised by the Company
        subsequent to accepting an Order in the event of any occurrence
        affecting delivery caused by government action, variation in customs
        duties, increased shipping charges, higher foreign exchange costs and
        any other matter beyond the control of the Company. In that event, You
        will have the right to cancel Your Order.
      </p>

      <h4>Payments</h4>
      <p>
        Unless otherwise provided in these Terms and Conditions (see, e.g.
        Subscriptions, below) Goods purchased are subject to a one-time payment.
        Payment can be made through various payment methods we have available,
        such as Visa, MasterCard, Affinity Card, American Express cards or
        online payment methods (PayPal, for example). Payment cards (credit
        cards or debit cards) are subject to validation checks and authorization
        by Your card issuer. If we do not receive the required authorization, We
        will not be liable for any delay or non-delivery of Your Order.
      </p>
      <br />
      <h3>Subscriptions</h3>
      <br />
      <h4>Subscriptions & Automatic Renewal</h4>
      <p>
        By purchasing a Subscription, you agree to both an initial and recurring
        Monthly Subscription for products at the then-current Monthly
        Subscription rate. You will be billed in advance and on a recurring and
        periodic basis (such as daily, weekly, monthly, or annually) depending
        on the type of subscription You chose. Your subscription will
        automatically renew unless You cancel it. You accept responsibility for
        all recurring charges until You cancel Your subscription. You can cancel
        Your subscription at any time. In the event You cancel your
        subscription, You will not be refunded any amount, pro-rata or
        otherwise. However, You will not incur any additional charges.
        Subscription orders are subject to processing times.
      </p>

      <h4>Automatic Monthly Renewal Terms: </h4>
      <p>
        We will automatically process your payments, and will charge Your credit
        card, for the then current Monthly Subscription rate, until You cancel
        your subscription. We explain how to cancel your subscription below.
      </p>

      <p>
        If We do not receive payment from Your credit card provider, or Your
        credit card is rejected or expires, You will pay all amounts due upon
        demand. We may allow You to provide a second credit card in such an
        event. In the event You do so, You authorize us to charge your
        outstanding balance and other amounts due against any credit card You
        have on file with us. In order to cancel Your subscription, You should
        login into MY ACCOUNT, proceed to BILLING, and select CANCEL PLAN. If
        You cancel in the middle of your subscription, You will receive all the
        boxes for the length of the contract. Canceling simply means Your
        subscription will not auto renew. But, if You signed up for a six month
        subscription, all six months will be billed and charged, You will
        receive all six boxes, and Your subscription will not auto renew. You
        will not receive a refund for any fees You already paid for Your
        subscription. Except when required by law, paid Subscription fees are
        non-refundable. Certain refund requests for Subscriptions may be
        considered by the Company on a case-by-case basis and granted at the
        sole discretion of the Company.
      </p>

      <p>
        3, 6, 9, and 12 Month Gift subscriptions are not eligible to for
        cancellation.
      </p>

      <h4> Fee Changes</h4>
      <p>
        The Company, in its sole discretion and at any time, may modify the
        Subscription fees. Any Subscription fee change will become effective at
        the end of the then-current subscription period. The Company will
        provide You with reasonable prior notice of any change in subscription
        fees to give You an opportunity to terminate Your Subscription before
        such change becomes effective. Your continued use of the Service after
        the Subscription fee change comes into effect constitutes Your agreement
        to pay the modified Subscription fee amount.
      </p>
      <br />
      <h3>Promotions</h3>
      <p>
        Any Promotions made available through the Service may be governed by
        rules that are separate from these Terms.
      </p>
      <p>
        If You participate in any Promotions, please review the applicable rules
        as well as our Privacy policy. If the rules for a Promotion conflict
        with these Terms, the Promotion rules will apply.
      </p>
      <br />

      <h3>SMS Program</h3>
      <h4>Mobile Terms & Conditions</h4>
      <p>
        Bump Boxes offers its customers marketing and promotional mobile alerts
        (including cart reminders) by SMS message (the “Service“) on 63846. By
        participating in the Service, you are agreeing to these Terms and to the{' '}
        <Link to='/privacy-policy'>Privacy Policy</Link>.
      </p>

      <h5>Signing Up and Opting-In to the Service</h5>
      <p>
        Enrollment in the Service requires you to provide your mobile phone
        number and to agree to these terms and conditions. You may not enroll if
        you are under 18 years old. Before the Service will start, you will need
        to agree to these Terms. Bump Boxes reserves the right to stop offering
        the Service at any time with or without notice. Bump Boxes also reserves
        the right to change the short code or phone number from which messages
        are sent and we will notify you when we do so.
      </p>
      <p>By opting into the Service, you:</p>
      <ul>
        <li>
          Authorize Bump Boxes to use an automatic telephone dialing system to
          send recurring automated text messages to the mobile phone number
          associated with your opt-in (i.e., the number listed on the opt-in
          form or, if none, the number from which you send the opt-in, or, if
          none, the number on file for the account associated with your opt-in).
        </li>
        <li>
          Acknowledge that you do not have to agree to receive messages as a
          condition of purchase.
        </li>
        <li>
          Confirm that you are the subscriber to the relevant phone number or
          that you are the customary user of that number on a family or business
          plan and that you are authorized to opt in.
        </li>
        <li>
          Consent to the use of an electronic record to document your opt-in. To
          request a free paper or email copy of the opt-in or to update our
          records with your contact information, contact info@BumpBoxes.com. To
          view and retain an electronic copy of these Terms or the rest of your
          opt-in, you will need (i) a device (such as a computer or mobile
          phone) with Internet access, and (ii) and either a printer or storage
          space on such device. For an email copy, you will also need an email
          account you can access from the device, along with a browser or other
          software that can display the emails.
        </li>
      </ul>

      <h5>Content You May Receive</h5>
      <p>
        Once you affirm your choice to opt-in to the Service on 63846, your
        message frequency may vary based on your interactions and initiated
        texts. You may receive alerts about:
      </p>
      <ul>
        <li>Sale promotions</li>
        <li>Event information</li>
        <li>Product launch announcements</li>
        <li>Cart reminders</li>
        <li>Back in stock alerts</li>
        <li>Price drop alerts</li>
        <li>Low inventory alerts</li>
      </ul>

      <h5>Charges and Carriers</h5>
      <p>
        Message and data rates may apply. Please consult your service agreement
        with your wireless carrier or contact your wireless carrier to determine
        your phone’s pricing plan and the charges for sending and receiving text
        messages. You acknowledge that you are responsible for any message, data
        or other charges incurred (usage, subscription, etc.) as a result of
        using the Service.
      </p>
      <p>
        Supported carriers are AT&T, T-Mobile, Verizon Wireless, Sprint, Boost,
        Virgin Mobile, U.S. Cellular, Cricket, Alltel, Cincinnati Bell, Cellcom,
        C-Spire, nTelos, MetroPCS, and other smaller regional carriers. The
        Service may not be available on all wireless carriers. Bump Boxes may
        add or remove any wireless carrier from the Service at any time without
        notice. Bump Boxes and the mobile carriers are not responsible for any
        undue delays, failure of delivery, or errors in messages.
      </p>

      <h5>To Stop the Service</h5>
      <p>
        To stop receiving text messages from Bump Boxes, text the word STOP to
        63846 any time or reply STOP to any of the text messages you have
        received from Bump Boxes. This is the exclusive method for opting out.
        After texting STOP to 63846, you will receive one additional message
        confirming that your request has been processed.
      </p>

      <h5>Questions</h5>
      <p>
        You can text HELP for help at any time to 63846. This will provide you
        with our customer service email address at info@BumpBoxes.com.
      </p>

      <h5>Changes to Terms</h5>
      <p>
        These Mobile Terms and Conditions are subject to change at any time
        without notice.
      </p>

      <h4>Arbitration and Class Action Waiver</h4>
      <p>
        Please read this carefully. It affects your rights. Any dispute or claim
        relating in any way to your use of Our services will be resolved by
        binding arbitration, rather than court.
      </p>
      <p>
        This agreement to arbitrate is intended to be broadly interpreted, and
        expressly includes claims brought under the Telephone Consumer
        Protection Act, 47 U.S.C. § 227 et seq., or any other statute,
        regulation, or legal or equitable theory. You and we, Bump Health, Inc.,
        hereby agree that the Federal Arbitration Act, 9 U.S.C. 1, et seq.
        (“FAA”) applies to this agreement to arbitrate, and governs all
        questions of whether a dispute is subject to arbitration. Unless you and
        we agree otherwise in writing, arbitration shall be administered by the
        American Arbitration Association’s Consumer Arbitration Rules in effect
        at the time of filing of the arbitration (the “AAA’s Rules”). However,
        just as a court would, the arbitrator or arbitrators must honor the
        terms and limitations in this Agreement, and can award damages and
        relief (including any attorneys’ fees) authorized by law and/or the
        AAA’s Rules. The arbitration decision and award is final and binding,
        with some exceptions under the FAA, and judgment on the award may be
        entered in any court of competent jurisdiction. YOU AGREE THAT, BY
        ENTERING INTO THIS AGREEMENT, YOU AND BUMP HEALTH, INC. ARE EACH WAIVING
        THE RIGHT TO A TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN ANY CLASS,
        REPRESENTATIVE, OR COLLECTIVE PROCEEDING.
      </p>
      <p>
        THIS AGREEMENT DOES NOT ALLOW FOR CLASS ARBITRATIONS EVEN IF THE
        PROCEDURES OR RULES OF THE AAA WOULD. RATHER, YOU AND WE ARE ONLY
        ENTITLED TO PURSUE ARBITRATION ON AN INDIVIDUAL, BILATERAL BASIS.
        FURTHER, AND UNLESS YOU AND BUMP HEALTH, INC. AGREE OTHERWISE IN
        WRITING, THE ARBITRATOR(S) MAY NOT CONSOLIDATE MORE THAN ONE INDIVIDUAL
        PARTY’S CLAIMS WITH ANY OTHER PARTY’S CLAIMS, AND MAY NOT OTHERWISE
        PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR COLLECTIVE PROCEEDING. You
        and Bump Health, Inc. are each responsible for our respective costs
        relating to counsel, experts, and witnesses, as well as any other costs
        relating to the arbitration. Bump Health, Inc. however, will pay for the
        arbitration administrative or filing fees, including the arbitrator
        and/or other AAA case management fees, for any claim seeking $75,000 or
        less, unless the claim is determined by the arbitrator to be frivolous.
        Otherwise, the AAA’s Rules regarding costs and payment apply.
      </p>
      <p>
        This agreement to arbitrate does not preclude you from bringing issues
        to the attention of federal, state, or local agencies. Such agencies
        can, if the law allows, seek relief against us on your behalf.
      </p>
      <p>
        The arbitrator may award injunctive relief only favor of the individual
        party seeking relief and only to the extent necessary to provide relief
        warranted by that party’s individual claim.
      </p>
      <p>
        If You wish to arbitrate, you must first send, by U.S. certified mail, a
        written Notice of Dispute (“Notice”) to the other party. A Notice to Us
        should be addressed to: Notice of Dispute, Bump Boxes, Inc., 7719 North
        Pioneer Lane, Peoria, Illinois 61615 with a copy, also sent U.S.
        certified mail, to Jonathan Phillips, Bathke &amp; Phillips, P.C. 4541
        North Prospect Road, Suite 300A, Peoria Heights, Illinois 61616. The
        Notice must (a) describe the nature and basis of the claim or dispute
        and (b) set forth the specific relief sought (“Demand”). If We and You
        do not reach an agreement to resolve the claim within 30 days after the
        Notice is received, You or We may commence an arbitration proceeding. If
        your claim is for $10,000 or less, you may choose whether the
        arbitration will be conducted solely on the basis of documents submitted
        to the arbitrator, by a telephonic hearing, or by an in-person hearing
        as established by the AAA Rules. If you choose to proceed either in
        person or by telephone, we may choose to respond only by telephone or
        submission. If your claim exceeds $10,000, the AAA Rules will determine
        whether you have a right to a hearing. Regardless of the manner in which
        the arbitration is conducted, the arbitrator shall issue a reasoned
        written decision sufficient to explain the essential findings of fact
        and conclusions of law on which the award is based. The parties agree
        that any awards or findings of fact or conclusions of law made in an
        arbitration of their dispute or claim are made only for the purposes of
        that arbitration, and may not be used by any other person or entity in
        any later arbitration of any dispute or claim involving Bump Health,
        Inc. The parties agree that in any arbitration of a dispute or claim,
        neither party will rely for preclusive effect on any award or finding of
        fact or conclusion of law made in any other arbitration of any dispute
        or claim to which We were a party. Unless We and You agree otherwise,
        any arbitration hearings will take place in Peoria County, Illinois.
      </p>
      <p>
        If any term of this Section (Arbitration and Class Action Waiver) is to
        any extent illegal, otherwise invalid, or incapable of being enforced,
        such term shall be excluded to the extent of such invalidity or
        unenforceability; all other terms hereof shall remain in full force and
        effect; and, to the extent permitted and possible, the invalid or
        unenforceable term shall be deemed replaced by a term that is valid and
        enforceable and that comes closest to expressing the intention of such
        invalid or unenforceable term. If application of this Severability
        provision should materially and adversely affect the economic substance
        of the transactions contemplated hereby, the Party adversely impacted
        shall be entitled to compensation for such adverse impact, provided the
        reason for the invalidity or unenforceability of a term is not due to
        serious misconduct by the Party seeking such compensation.
      </p>
      <hr />
      <p>
        The Bump Boxes mobile message service (the “SMS Service“) is operated by
        Bump Health, Inc., (“Bump Boxes,” “we,” or “us”). Your use of the SMS
        Service constitutes your agreement to these terms and conditions
        (“Mobile Terms”). We may modify or cancel the SMS Service or any of its
        features without notice. To the extent permitted by applicable law, we
        may also modify these Mobile Terms at any time and your continued use of
        the Service following the effective date of any such changes shall
        constitute your acceptance of such changes.
      </p>

      <p>
        By consenting to our SMS/text messaging service, you agree to receive
        recurring SMS/text messages from and on behalf of us through your
        wireless provider to the mobile number you provided, even if your mobile
        number is registered on any state or federal Do Not Call list. Text
        messages may be sent using an automatic telephone dialing system or
        other technology. Service-related messages may include updates, alerts,
        and information (e.g., order updates, account alerts, etc.). Promotional
        messages may include promotions, specials, and other marketing offers
        (e.g., cart reminders).
      </p>
      <p>
        If you opt into our SMS Service, you will receive order updates, account
        alerts, and other account related information. Services will be sent
        from us via text messages through your wireless provider to the mobile
        number you provided.
      </p>

      <p>
        You understand that you do not have to sign up for this program in order
        to make any purchases, and your consent is not a condition of any
        purchase with us. Your participation in this program is completely
        voluntary.
      </p>

      <p>
        We do not charge for the SMS Service, but you are responsible for all
        charges and fees associated with text messaging imposed by your wireless
        provider. Message frequency varies. Message and data rates may apply.
        Check your mobile plan and contact your wireless provider for details.
        You are solely responsible for all charges related to SMS/text messages,
        including charges from your wireless provider.
      </p>
      <p>
        {' '}
        You may opt-out of the SMS Service at any time. Text the single keyword
        command STOP to 63846 or click the unsubscribe link (where available) in
        any text message to cancel. You`ll receive a one-time opt-out
        confirmation text message. No further messages will be sent to your
        mobile device, unless initiated by you. If you have subscribed to other
        mobile message programs operated by us and wish to cancel, except where
        applicable law requires otherwise, you will need to opt out separately
        from those programs by following the instructions provided in their
        respective mobile terms. For Service support or assistance, text HELP to
        63846 or email info@bumpboxes.com with copy to jlap@pb-iplaw.com.
      </p>
      <p>
        We may change any short code or telephone number we use to operate the
        SMS Service at any time and will notify you of these changes. You
        acknowledge that any messages, including any STOP or HELP requests, you
        send to a short code or telephone number we have changed may not be
        received and we will not be responsible for honoring requests made in
        such messages.
      </p>
      <p>
        The wireless carriers supported by the SMS Service are not liable for
        delayed or undelivered messages. You agree to provide us with a valid
        mobile number. If you get a new mobile number, you will need to sign up
        for the program with your new number. To the extent permitted by
        applicable law, you agree that we will not be liable for failed,
        delayed, or misdirected delivery of any information sent through the
        Service, any errors in such information, and/or any action you may or
        may not take in reliance on the information or SMS Service.
      </p>
      <p>
        We respect your right to privacy. To see how we collect and use your
        personal information, please see our
        <Link to='/privacy-policy'> Privacy Policy</Link>
      </p>
      <br />

      <h3>User Accounts</h3>
      <p>
        When You create an account with Us, You must provide Us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of Your account on Our Service.
      </p>
      <p>
        You are responsible for safeguarding the password that You use to access
        the Service and for any activities or actions under Your password,
        whether Your password is with Our Service or a Third-Party Social Media
        Service.
      </p>
      <p>
        You agree not to disclose Your password to any third party. You must
        notify Us immediately upon becoming aware of any breach of security or
        unauthorized use of Your account. You may not use as a username the name
        of another person or entity or that is not lawfully available for use, a
        name or trademark that is subject to any rights of another person or
        entity other than You without appropriate authorization, or a name that
        is otherwise offensive, vulgar or obscene.
      </p>
      <br />
      <h3>Intellectual Property</h3>
      <p>
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </p>

      <p>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </p>

      <p>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </p>

      <h4>Your Feedback to Us</h4>
      <p>
        You assign all rights, title and interest in any Feedback You provide
        the Company. If for any reason such assignment is ineffective, You agree
        to grant the Company a non-exclusive, perpetual, irrevocable, royalty
        free, worldwide right and license to use, reproduce, disclose,
        sub-license, distribute, modify and exploit such Feedback without
        restriction.
      </p>
      <h4>Submitted Content</h4>
      <p>
        This section governs any content, including but not limited to images,
        videos, photographs, text, or comments (“Submissions”), You submit to
        Us, post on any of Our websites or social media accounts, or otherwise
        publish in a way that suggests a connection with Us. By submitting a
        Submission, you agree whether We publish it or not, We do not guaranty
        confidentiality of Your Submission. The following terms apply to your
        Submissions. You agree you are eighteen years old. All identifiable
        persons in any Submission are at least eighteen years old at the time
        the content of the Submission was created. You have, either through
        securing from others or by virtue of being a content creator or
        inventor, all necessary rights, including but not limited to,
        intellectual property rights and rights of privacy or publicity,
        sufficient to allow You to make the Submission. No other party has
        rights in Your Submission. You provide Us with a non-exclusive,
        unlimited, royalty-free, worldwide, perpetual license to those rights,
        sufficient for Us to make use of the Submission in any way We see fit,
        including, but not limited to, copying, making derivative works,
        publicly displaying, publicly performing, distributing, destroying,
        selling, transferring, sub-licensing, or offering for sale. You agree
        the Submission is not immoral, obscene, illegal and does not portray any
        illegal activities. You have no right to review, inspect, or approve any
        of Our Use of the Submission. Our license survives the termination of
        any contractual relationship between You and Us. In the event a
        third-party asserts any claim against Us claiming he, she, or it has
        rights to a Submission, You agree to indemnify Us and hold Us harmless
        for Our use of the Submission.
      </p>

      <h4>Digital Millennium Copyright Act & Intellectual Property</h4>

      <p> We respect others’ intellectual property rights.</p>

      <p>
        If you believe that your copyrighted work has been copied in a way that
        constitutes infringement and is accessible by the Service, please notify
        Our copyright agent, as set forth in the Digital Millennium Copyright
        Act of 1998 (“DMCA”). For your complaint to be valid under the DMCA, you
        must provide the following information in writing:
      </p>

      <ol>
        <li>
          An electronic or physical signature of a person authorized to act on
          behalf of the copyright owner.
        </li>
        <li>Identification of the copyrighted work you claim is infringed.</li>
        <li>
          Identification of the material you claim is infringing and where it is
          located on the Service.
        </li>
        <li>
          Information reasonably sufficient to permit Us to contact you, such as
          an address, telephone number, and email address.
        </li>
        <li>
          A statement you have a good faith belief that use of the material in
          the manner complained of is not authorized by the copyright owner, its
          agent, or law.
        </li>
        <li>
          A statement, made under penalty of perjury, that the above information
          is accurate, and that you are the copyright owner or are authorized to
          act on behalf of the owner.
        </li>
      </ol>

      <p>You must submit this information to the following DMCA agent:</p>

      <ul>DMCA Agent </ul>
      <ul>Bump Health, Inc.</ul>
      <ul>c/o Jonathan LA Phillips</ul>
      <ul>Phillips & Bathke, P.C.</ul>
      <ul>4541 North Prospect Road</ul>

      <ul>Suite 300A</ul>
      <ul>Peoria, Illinois 61616</ul>
      <ul>Email: jlap@pb-iplaw.com</ul>

      <p>
        If your Submission is removed as a result of a DMCA notice, You may
        provide us with a DMCA counter-notice. To do so, you must include the
        following information:
      </p>

      <ol>
        <li>
          Your name, address, telephone number, and email address (if any).
        </li>
        <li>
          A description of the material that was removed and its location where
          it previously appeared.
        </li>
        <li>
          A statement under penalty of perjury that you have a good faith belief
          the material was removed or disabled as a result of mistake or
          misidentification.
        </li>
        <li>
          A statement you consent to the jurisdiction of the United States
          District Court in which your address is located or, if your address is
          outside the United States, any jurisdiction in which you may be
          properly served, and that you will accept service of process from the
          person who filed the original DMCA notice or an agent of that person.
        </li>
        <li>Your electronic signature.</li>
      </ol>

      <p>
        If you believe some other intellectual property right you have an
        interest in is infringed, you may notify us at:
      </p>

      <ul>IP Infringement</ul>
      <ul>Bump Health, Inc.</ul>
      <ul>c/o Jonathan LA Phillips</ul>
      <ul>Phillips & Bathke, P.C.</ul>
      <ul>4541 North Prospect Road</ul>
      <ul>Suite 300A</ul>

      <ul>Peoria, Illinois 61616</ul>
      <ul>Email: jlap@pb-iplaw.com</ul>

      <br />
      <h3>Links to Other Websites</h3>

      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </p>

      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third-party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>

      <p>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </p>

      <br />
      <h3>Termination</h3>
      <p>
        We may terminate or suspend Your Account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if You breach these Terms and Conditions.
      </p>
      <p>
        Upon termination, Your right to use the Service will cease immediately.
        If You wish to terminate Your Account, You may simply discontinue using
        the Service.
      </p>

      <br />
      <h3>Limitation of Liability</h3>
      <p>
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You through the Service or 100
        USD if You haven’t purchased anything through the Service.
      </p>

      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </p>

      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party’s liability will be limited to the greatest extent permitted
        by law.
      </p>
      <br />

      <h4>“AS IS” and “AS AVAILABLE” Disclaimer</h4>
      <p>
        The Service is provided to You “AS IS” and “AS AVAILABLE” and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company, on its own behalf and on
        behalf of its Affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the Service, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected. You are also cautioned that it is your responsibility to
        review ingredients of each Good you receive from Us to avoid allergic
        reactions or other side effects. Some Goods are manufactured by Us,
        others by Our suppliers.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company’s provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>

      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        You. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </p>
      <br />

      <h3>Medical Disclaimer:</h3>
      <p>
        This website contains general information about conception, pregnancy,
        pregnancy products and parenting. The information is not complete or
        comprehensive. You should not rely on the information on this website as
        an alternative to medical advice from your doctor or healthcare
        provider. Nothing contained on this web site should be construed nor is
        intended to be used for medical diagnosis or treatment. It should not be
        used in place of the advice of your physician or other qualified
        healthcare provider. Should you have any healthcare related questions,
        please call or see your physician or other qualified healthcare provider
        promptly.
      </p>

      <p>
        Always consult with your physician or other qualified healthcare
        provider before embarking on a new treatment, diet or fitness program.
        If you have any specific questions about these matters you should
        consult your doctor or other healthcare provider. If you think you may
        be pregnant you should speak to a doctor or other healthcare provider,
        and if you think you may be suffering from any other medical condition
        you should seek immediate medical attention.
      </p>

      <p>
        You should never delay seeking medical advice, disregard medical advice,
        or discontinue medical treatment because of information on this website.
        You are encouraged to consult your physician or your local healthcare
        provider to obtain professional medical advice, which may agree or
        disagree with the information and materials contained on this site. You
        should consult your own physician or your local healthcare provider
        regarding your own symptoms, medical condition, treatment or
        medications. If you have or suspect that you have a medical problem, you
        should contact your own physician or local healthcare provider
        immediately.
      </p>

      <p>
        While the information published on this site is believed to be accurate,
        it has been published solely for the purpose of provide general
        information and is not intended as a substitute for professional medical
        advice. If you have questions or concerns regarding your physical health
        or the health of your baby, please seek assistance from a qualified
        healthcare provider. The articles on this site are meant to answer
        questions of a general nature regarding issues of interest surrounding
        pregnancy. If you have a specific medical concern, please contact your
        healthcare provider.
      </p>

      <p>
        Call Your Doctor or Emergency Services in Case of Emergency. If you
        think you may have a medical emergency, call immediately. DO NOT USE
        THIS WEB SITE FOR MEDICAL EMERGENCIES.
      </p>

      <p>
        By using the Services, you understand and accept We are not a doctor,
        medical professional, licensed nutritionist, or registered dietician.
        You understand and accept statements within the Services have not been
        evaluated by the Food and Drug Administration. No products or
        information are intended to diagnose, treat, cure or prevent any
        disease.
      </p>

      <p>
        The information about the use of herbs and essential oils contained in
        this site is not meant to be a substitute for seeking the advice of a
        qualified health care provider. It is not intended to diagnose, treat,
        cure or prevent any disease. Always consult your health care provider
        about the use of herbs and essential oils, especially during pregnancy,
        when nursing a baby or with children. All images, text, design and
        layouts are copyright protected under the Federal Copyright Law and are
        the sole property of Bump Boxes, Inc. No form of reproduction of these
        images and photographs, including downloading, copying or saving of
        digital image files is authorized without the express written consent of
        Bump Boxes, Inc.
      </p>
      <br />

      <h3>Promotional Offers</h3>
      <p>
        By creating an account with Us, using the Services, or making a
        purchase, You consent to receive content, advertisements, or promotional
        materials in any manner We choose to deliver them. This includes, but is
        not limited to, email and SMS messaging – which you may incur a fee from
        your cell phone provider.
      </p>

      <br />
      <h4>SMS/MMS Mobile Message Marketing Program Terms and Conditions</h4>

      <p>
        We offer a mobile messaging program (the “Mobile Program”), By opting in
        to or participating in any of our programs, including the Mobile
        Program, you accept and agree our Terms and Conditions. By creating an
        account, using the Service, or providing us your phone number, You are
        opting in to the Mobile Program.
      </p>

      <p>
        The Mobile Program allows participants to receive SMS/MMS mobile
        messages by opting into the Mobile Program, such as through online or
        application-based enrollment forms, creating an account, or using the
        Service and providing your phone number. Regardless of the opt-in method
        You utilized to join the Mobile Program, You agree these Terms apply to
        Your participation in the Mobile Program. By participating in the Mobile
        Program, You agree to receive autodialed or prerecorded marketing mobile
        messages at the phone number associated with Your account and You
        understand that consent is not required to make any purchase from us.
        While You consent to receive messages sent using an autodialer, these
        Terms will not be interpreted to suggest or imply that any of Our mobile
        messages are sent using an automatic telephone dialing system (“ATDS” or
        “autodialer”). You also agree to receive SMS/MMS messages from Us.
        Message and data rates may apply.
      </p>

      <h5>User Opt Out: </h5>
      <p>
        If You do not wish to continue participating in the Mobile Program or no
        longer agree to this Terms, you agree to reply STOP, END, CANCEL,
        UNSUBSCRIBE, or QUIT to any mobile message from Us in order to opt out
        of the Program. You may receive an additional mobile message confirming
        Your decision to opt out. You understand and agree that these options
        are the only reasonable methods of opting out. You also understand and
        agree that any other method of opting out, including, but not limited
        to, texting words other than those set forth above or verbally
        requesting one of Our employees or agents to remove You from Our list,
        is not a reasonable means of opting out. Necessary steps concerning
        exercising rights you might have under the GDPR or CCPA are provided in
        the Privacy Policy.
      </p>

      <h5> Duty to Notify and Indemnification:</h5>
      <p>
        If at any time You intend to stop using the mobile telephone number that
        has been used to subscribe to the Mobile Program, including canceling
        Your service plan or selling or transferring the phone number to another
        party, You agree that You will complete the User Opt Out process set
        forth above prior to ending Your use of the mobile telephone number. You
        understand and agree that your agreement to do so is a material part of
        these Terms. You further agree that, if You discontinue the use of Your
        mobile telephone number without notifying us of such change, You agree
        that You will be responsible for all costs (including attorneys’ fees)
        and liabilities incurred by Us, or any party that assists Us in the
        Mobile Program, as a result of claims brought by individual(s) who are
        later assigned that mobile telephone number. This duty and agreement
        survive any cancellation or termination of Your agreement to participate
        in our Services, including the Mobile Program.
      </p>

      <br />
      <h6>
        <strong>
          AMONG OTHER DUTIES AS SET FORTH IN THE TERMS AND CONDITIONS AND THE
          PRIVACY POLICY, YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD
          US HARMLESS FROM ANY CLAIM OR LIABILITY RESULTING FROM YOUR FAILURE TO
          NOTIFY US OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED, INCLUDING
          ANY CLAIM OR LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47
          U.S.C. § 227, et seq., OR SIMILAR STATE AND FEDERAL LAWS, AND ANY
          REGULATIONS PROMULGATED THEREUNDER RESULTING FROM US ATTEMPTING TO
          CONTACT YOU AT THE MOBILE TELEPHONE NUMBER YOU PROVIDED.
        </strong>
      </h6>
      <br />

      <p>
        Nothing under the heading “Duty to Notify and Indemnification” restricts
        or limits your duties to indemnify us or restricts our limits our rights
        under the Terms or Privacy Policy. Mobile Program Description: Without
        limiting the scope of the Mobile Program, users that opt into the Mobile
        Program can expect to receive messages concerning the marketing and sale
        of digital and physical products, services, and events. Your election to
        not opt in to the Mobile Program will not prevent You from asking for
        and receiving any financial incentives provided through the Mobile
        Program.
      </p>
      <br />

      <h5> Cost and Frequency:</h5>
      <p>
        Message and data rates may apply. The Program involves recurring mobile
        messages, and additional mobile messages may be sent periodically based
        on your interaction with Us.
      </p>

      <h5> Support Instructions:</h5>
      <p>
        For support regarding the Program, text “HELP” to the number You
        received messages from or email Us. Please note that the use of email is
        not an acceptable method of opting out of the program. Opt outs must be
        submitted as set forth above.
      </p>

      <h5> MMS Disclosure:</h5>
      <p>
        The Mobile Program will send SMS TMs (terminating messages) if your
        mobile device does not support MMS messaging.
      </p>
      <h5> Our Disclaimer of Warranty: </h5>
      <p>
        The Mobile Program is offered on an “as-is” basis and may not be
        available in all areas at all times and may not continue to work in the
        event of product, software, coverage or other changes made by Your
        wireless carrier. We are not be liable for any delays or failures in the
        receipt of any mobile messages connected with this Program. Delivery of
        mobile messages is subject to effective transmission from Your wireless
        service provider/network operator and is outside of Our control.
      </p>

      <h5> Participant Requirements: </h5>
      <p>
        You must have a wireless device of Your own, capable of two-way
        messaging, be using a participating wireless carrier, and be a wireless
        service subscriber with text messaging service. Not all cellular phone
        providers carry the necessary service to participate. Check Your phone
        capabilities for specific text messaging instructions.
      </p>

      <h5> Age Restriction:</h5>
      <p>
        You must be comply with age restrictions of the Terms to participate in
        the Mobile Program and in all events, must be at least eighteen years of
        age to participate in the Mobile Program.
      </p>
      <br />

      <h3>Referral Program</h3>
      <p>
        This section applies to Our Referral, affiliates, and Rewards Program
        (the “Referral Program”). Under the Referral Program We offer affiliates
        and customers the opportunity to provide friends, family, or others
        (“Customers and Prospective Customers”) with a unique referral ID link
        (“Personal Link”) that a Prospective Customer can use to sign up for Our
        Services. For each Qualified Referral/Review (defined below) generated
        using a Personal Link, the participant may receive compensation in the
        form specified on Our Referral/Rewards webpage. We can terminate the
        Referral Program at any time.
      </p>
      <p>
        If You wish to take part in the Referral Program, these Terms apply to
        You. If You do not agree to these Terms, then You may not participate in
        the Referral Program. Neither You nor Prospective Customers may
        participate in the Referral Program when doing so would violate any
        applicable law or regulation. Neither You nor a Prospective Customer can
        be under the age of thirteen and still participate in the Referral
        Program. We reserve the right to disqualify You or any Prospective
        Customer from participation in the Referral Program for any reason at
        any time.
      </p>

      <h4>Use of Information Collected</h4>
      <p>
        Prospective Customers that wish to use a Personal Link must submit
        personal information about themselves, such as name and e-mail address
        information, so that the Company can track the Customer’s Personal Link
        to their account and prescribe them the Credit. The personal information
        will be collected, processed and used in accordance with the Company’s
        Privacy Policy, which can be found at www.bumpboxes.com In addition,
        personal information provided by the Prospective Customer may be used by
        the Company or its service provider, on Company’s behalf, to contact
        Customers or Prospective Customers with regards to their participation
        in the Referral Program or for any purpose and means provided in
        Promotional Offers above. We may send out additional follow-up
        communications to either the Customer or Prospective Customer to
        encourage or remind them to refer more Prospective Customers or to
        complete the registration process. By participating in the Program,
        Customers consent to receiving such communications by email.
      </p>

      <br />
      <h4>How the Program Works</h4>
      <br />
      <h5>Program Participation</h5>
      <p>
        To participate, You must provide to the Prospective Customer Your
        Personal Link, Review a product, or purchase a product. The Prospective
        Customer must click on the Personal Link and complete the sign up in the
        same session. If the Prospective Customer does not complete the sign up
        in the same session, the You will be not be credited for that referral
        and will not be eligible for the Credit, even if the Prospective
        Customer subsequently signs up. For purposes of clarification completion
        of sign-up in the same session means clicking the Customer Personal Link
        provided by You and completing Our service sign-up within a reasonable
        period of time and without the Prospective Customer closing their
        Internet browser or pressing the back button.
      </p>
      <p>To be eligible for compensation, You must:</p>

      <li>Be a legal resident of the United States;</li>
      <li>Be at least 18 years old;</li>
      <li>
        Have provided the Prospective Customer with Your correct unique Personal
        Link, Product Reviews, and Product Purchases; and
      </li>
      <li>Have a Customer ship account in good standing with Us.</li>
      <br />

      <p>
        Employees of the Company, its service providers, or any of its or their
        subsidiaries, affiliates or promotional agencies, including immediate
        family and household Customers, are not eligible to participate in the
        Referral Program. Personalized Referral Links
      </p>

      <p>
        We offer You the opportunity to create Your own personalized referral
        name (“Personalized Referral Links”). Personalized Referral Links may be
        of any length and include any combination of words and/or numbers of
        Your choosing. Personalized Referral Links shall be on a first come,
        first served basis.{' '}
      </p>

      <p>
        You can check Your Personalized Referral Link by going to
        www.bumpboxes.com. We are in no way responsible of correcting or
        insuring that the Personalized Referral Links is accurate or correctly
        formatted or spelled. Under no circumstances may a Personalized Referral
        Links be transferred or sold to another Customer. Notwithstanding the
        foregoing the We reserve the right to revoke, ban, or amend any
        Personalized Referral Links at any time and for any reason. Any
        compensation earned through a revoked, banned, or amended Personalized
        Referral Link may be removed from Your account by Us. For purposes of
        clarification, but in no way limiting the Company’s rights to revoke,
        ban, or amend Personalized Referral Links, the following Personalized
        Referral Links are explicitly banned and may be grounds for suspension
        of Your account, participation in the Referral Program, and removal of
        any Credits earned:
      </p>

      <li>
        Personalized Referral Links that imply a discount or savings on
        Membership (ie: 5DollarsOff, etc.)
      </li>
      <li>
        Personalized Referral Links that are deemed to be obscene, racist,
        sexist, or offensive in the sole discretion of the Company
      </li>
      <li>
        Personalized Referral Links that use a trademark, copyright, or the name
        of another individual
      </li>
      <br />

      <h4>Making a Referral</h4>
      <p>
        You can refer Prospective Customers by giving Your Personal Link to the
        Prospective Customer. Once a Prospective Customer uses a Your Personal
        Link and successfully the Prospective Customer signs up for Our
        qualifying services, You may receive a communication from Us confirming
        the Prospective Customer has signed up. The confirmation communication
        to You shall include some of the Prospective Customer’s personal
        information provided to Company such as name and email address. If You
        do not receive such a confirmation communication, then the Prospective
        Customer did not sign up properly and no Credit will have been earned.
        You and/or the Prospective Customer may email Company any comments or
        questions in relation to the failed referral, but it is at Our sole
        discretion to award You the Credit. You must only refer Prospective
        Customers who meet the requirements of these Terms and who have
        consented to receiving communications from Us under these Terms. You
        cannot refer Yourself.
      </p>

      <p>
        For purposes of emphasis and clarification, but not limitation, by using
        a Personal Link, Prospective Customers understand that some of their
        personal information, including but not limited to their name and email
        address, may be sent to the Customer that provided their Personal Link
        to the Prospective Customer for confirmation of sign up. As such, We
        strongly advise that if the Prospective Customer receives a Personal
        Link from an unknown, untrusted, or public source, such as an Internet
        forum, the Prospective Customer should not use the Personal Link for
        their own security purposes.
      </p>

      <h4>Qualified Referrals</h4>
      <p>
        A Qualified Referral means that all the following conditions are met:
        Each referral must be a $39.99 Monthly Subscription or Order over $49+
        to qualify. Store Credit cannot be applied to a new or active
        subscription.
      </p>

      <li>
        The Prospective Customer completed the registration process for a
        Membership using Your Personal Link. If a Prospective Customer registers
        for a Membership using any other link or method, the registration will
        not count as a Qualified Referral and the Customer will not be rewarded
        a Credit;
      </li>
      <li>
        The Prospective Customer was not previously registered with Our Service
        under any other email address or alias; and
      </li>
      <li>
        The Prospective Customer is a) at least 18 years old; b) meets the
        eligibility requirements to become a Customer Our Services; and c)
        consents to receiving communications from Us, including Promotional
        Offers as outlined in these Terms.
      </li>
      <br />

      <h4>Earning Credits for reviews or shopping</h4>
      <p>
        You will receive Credits for each verified Qualified Referral generated
        through Your Personal Link. The actual Credit will be determined by Us
        at Our discretion as specified on its Credits Page. If the Credit
        consists of credit such credit may only be applied in the amount
        specified by Us towards Your purchase of future services from Us. You
        may attain an unlimited number of Qualified Referrals, but any Qualified
        Referral shall be subject to review at any time by Us and it is in Our
        sole discretion to determine the legitimacy and validity of such
        Qualified Referral.
      </p>

      <h4>Write Honest Product Reviews</h4>
      <p>
        Earn 10 Bump Boxes Points for every product Reviewed by logging into
        your account at bumpboxes.com. From there, enter the “Shop” page. You
        can then click the image of the product You would like to review. Then,
        You may select Write a Review.
      </p>
      <p>
        Alternatively, You may go to “My Account” click view order and then
        click on the product You purchased. You can then fill out the feedback
        form and click “Submit.” You&apos;ll be credited with 10 Bump Boxes
        Points shortly after. You will receive no credits for products You have
        not purchased or that were not included in a subscription box sent to
        You.
      </p>
      <p>Alternatively, You may earn 1 Bump Boxes point for every $1 spent. </p>

      <h4>Verification of Credits</h4>
      <p>
        All Credits are subject to verification. We may delay a Credit for the
        purposes of investigation. We ma may also refuse to verify and process
        any transaction we deem, in Our sole discretion, to be fraudulent,
        suspicious, or in violation of these Terms, or We believe will impose
        potential liability on Us beyond what is reasonable for this Referral
        Program, our subsidiaries, affiliates, or any of their respective
        officers, directors, employees, representatives and agents. We may
        cancel, amend or revoke the Credit at any time due to business
        considerations of circumstances beyond Our control. We are not
        responsible for errors, omissions, malfunctions, interruptions,
        deletions, delays or failures of operations. All Our decisions are final
        and binding, including decisions as to whether a Qualified Referral or
        Credit is verified.
      </p>

      <h4>Value of Credit</h4>
      <p>
        Credits have no monetary value and may not, under any circumstances, be
        redeemed for cash or any cash equivalent including but not limited to
        gift cards, certificates, or refunds. We may cancel, amend or revoke the
        Credit at any time for any reason. Once at least $50 of credits have
        been recorded, We will issue a gift card in the amount of $50 to be used
        on items within the Shop page on BumpBoxes.com.
      </p>

      <h4>Transfer of Credits</h4>
      <p>
        Credits are personal to the You and are not transferable and may not be
        auctioned, traded, bartered or sold, whether by operation of law or
        otherwise. Upon termination of the Referral Program or any portion
        thereof for any reason, or upon termination of Your account for any
        reason, any unredeemed Credits (including without limitation any
        unapplied credits) accumulated by the Customer are forfeited.
      </p>

      <h4>Liability:</h4>
      <p>
        By participating in the Program, both You and Prospective Customers
        agree to be bound by these Terms, bound by Our decisions, bound by the
        Our Privacy Policy, to provide Indemnification as provided for (where
        Prospective Customers will also be “You”) in these Terms, and agree to
        receive Promotional Offers as provided in these Terms.
      </p>

      <p>We are not responsible or liable for:</p>

      <li>
        Late, lost, delayed, stolen, misdirected, incomplete, inaccurate,
        unreliable, garbled or unintelligible registrations, links,
        communications or affidavits, regardless of the method of transmission;
      </li>
      <li>
        data corruption, theft, destruction, unauthorized access to or
        alteration of entry or other materials;
      </li>
      <li>
        any injuries, losses or damages of any kind resulting from acceptance,
        possession or use of a Credit, or from participation in the Program,
        that were not reasonably foreseeable to the Company at the relevant
        time;
      </li>
      <li>
        any printing, typographical, administrative or technological errors in
        any websites or materials associated with the Program; or
      </li>
      <li>
        claims, demands, and damages in disputes among users of the Referral
        Program.
      </li>
      <br />

      <h3>Indemnification</h3>
      <p>
        You agree to indemnify, defend and hold Us, and our subsidiaries,
        affiliates, officers, parent companies, agents, co-branders or other
        partners, and employees (each a “Covered Party”), harmless, at your
        expense, against any and all claims, actions, proceedings, and suits and
        all related liabilities, damages, settlements, penalties, fines, costs
        and expenses (including, without limitation, reasonable attorneys’ fees
        and other dispute resolution expenses) incurred by any Covered Party or
        other third party and arising out of, (i) your use or misuse of the
        Service or Goods, (ii) arising from your breach of these Terms or any
        policies, rules, or guidelines referenced in these Terms.
      </p>
      <p>
        If You are a California resident, You waive California Civil Code
        Section 1542, which says: “A general release does not extend to claims
        that the creditor or releasing party does not know or suspect to exist
        in his or her favor at the time of executing the release and that, if
        known by him or her, would have materially affected his or her
        settlement with the debtor or released party” by creating an account or
        using the Service. If you are a resident of another jurisdiction, you
        waive any comparable statute or doctrine.
      </p>
      <br />

      <h3>Governing Law</h3>
      <p>
        Governing Law The laws of the State of Illinois, excluding its conflicts
        of law rules, shall govern this Terms and Your use of the Service. Your
        use of the Application may also be subject to other local, state,
        national, or international laws.
      </p>
      <br />

      <h3>Disputes Resolution</h3>
      <p>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company. Your
        failure to attempt to informally resolve the dispute will constitute
        Your forfeiture to assert any claim, action, proceeding, suit, or demand
        against Us. You agree to arbitrate, under these Terms, any dispute You
        have with Us after informal attempts at resolution fail.
      </p>

      <h4>Demand for Arbitration</h4>
      <p>
        We seek to resolve any customer concerns through Our Support services.
        If You are dissatisfied with Our customer service&apos;s resolution and
        seek further action, You and We agree to resolve disputes through
        binding arbitration court rather than in a court of general
        jurisdiction. Arbitration is less formal than a lawsuit in court.
        Arbitration uses a neutral arbitrator instead of a judge or jury, allows
        for more limited discovery than a court does, and is subject to very
        limited review by courts. Any arbitration under these Terms will take
        place on an individual basis; class arbitrations and class actions are
        not permitted.
      </p>

      <p>
        We are committed to resolving all disputes in a fair, effective, and
        cost-efficient manner. Accordingly, these Terms provide that disputes
        will be resolved in binding arbitration. Our arbitration terms, set
        forth below, are designed to make arbitration as convenient and
        inexpensive for Our customers as possible. You and We will abide by the
        terms of the current arbitration provision in all instances.
      </p>
      <br />

      <h4>“Arbitration Agreement”:</h4>
      <p>
        We and You agree to arbitrate all disputes and claims between us before
        a single arbitrator. The types of disputes and claims we agree to
        arbitrate are intended to be broadly interpreted. It applies, without
        limitation, to:
      </p>

      <ol>
        <li>
          Claims arising out of or relating to any aspect of the relationship
          between us, whether based in contract, tort, statute, fraud,
          misrepresentation, or any other legal theory.
        </li>
        <li>
          Claims that arose before these or any prior Terms (including, but not
          limited to, claims relating to advertising).
        </li>
        <li>
          Claims that are currently the subject of purported class action
          litigation in which you are not a member of a certified class.
        </li>
        <li>Claims that may arise after termination of these Terms.</li>
      </ol>

      <br />
      <p>
        For the purposes of this Arbitration Agreement, references to Our, We,
        Ours, or other words defined by these Terms to mean Bump Boxes, Inc.
        also include Our respective subsidiaries, affiliates, agents, employees,
        predecessors in interest, successors, and assigns, as well as all
        authorized or unauthorized users or beneficiaries of services or
        products under these Terms or any prior agreements between us.
      </p>
      <p>
        Notwithstanding the foregoing, this Arbitration Agreement does not
        preclude your bringing issues to the attention of federal, state, or
        local agencies. Such agencies can, if the law allows, seek relief
        against us on your behalf.
      </p>
      <p>
        You agree that, by entering into these Terms, You are waiving the right
        to a trial by jury or to participate in a class action. These Terms
        evidence a transaction or website use in interstate commerce, and thus
        the Federal Arbitration Act governs the interpretation and enforcement
        of this provision. This arbitration provision will survive termination
        of these Terms. If You wish to arbitrate, you must first send, by U.S.
        certified mail, a written Notice of Dispute (“Notice”) to the other
        party. A Notice to Us should be addressed to: Notice of Dispute, Bump
        Boxes, Inc., 7719 North Pioneer Lane, Peoria, Illinois 61615 with a
        copy, also sent U.S. certified mail, to Jonathan LA Phillips, Bathke &
        Phillips, P.C. 4541 North Prospect Road, Suite 300A, Peoria Heights,
        Illinois 61616 (“Notice address”). The Notice must (a) describe the
        nature and basis of the claim or dispute and (b) set forth the specific
        relief sought (“Demand”). If We and You do not reach an agreement to
        resolve the claim within 30 days after the Notice is received, You or We
        may commence an arbitration proceeding. The party initiating the
        arbitration is responsible for paying the fees associated with
        instituting the arbitration.
      </p>
      <p>
        During the arbitration, the amount of any settlement offer made by Us or
        You will not be disclosed to the arbitrator until after the arbitrator
        determines the amount, if any, to which We or You are entitled.
      </p>
      <p>
        The arbitration will be governed by the Commercial Dispute Resolution
        Procedures and the Supplementary Procedures for Consumer Related
        Disputes (collectively, the “AAA Rules”) of the American Arbitration
        Association (the “AAA”), as modified by these Terms, and will be
        administered by the AAA. The AAA Rules are available online at
        www.adr.org or by calling the AAA at 1-800-778-7879. The arbitrator is
        bound by these Terms. Unless We and You agree otherwise, any arbitration
        hearings will take place in Peoria County, Illinois. If your claim is
        for $10,000 or less, you may choose whether the arbitration will be
        conducted solely on the basis of documents submitted to the arbitrator,
        by a telephonic hearing, or by an in-person hearing as established by
        the AAA Rules. If you choose to proceed either in person or by
        telephone, we may choose to respond only by telephone or submission. If
        your claim exceeds $10,000, the AAA Rules will determine whether you
        have a right to a hearing. Regardless of the manner in which the
        arbitration is conducted, the arbitrator shall issue a reasoned written
        decision sufficient to explain the essential findings of fact and
        conclusions of law on which the award is based. The parties agree that
        any awards or findings of fact or conclusions of law made in an
        arbitration of their dispute or claim are made only for the purposes of
        that arbitration, and may not be used by any other person or entity in
        any later arbitration of any dispute or claim involving Us. The parties
        agree that in any arbitration of a dispute or claim, neither party will
        rely for preclusive effect on any award or finding of fact or conclusion
        of law made in any other arbitration of any dispute or claim to which We
        were a party.
      </p>

      <p>
        Except as otherwise provided for herein, the arbitrator will determine
        whether fees associated with institution of the arbitration shall be
        recovered by a prevailing party. If the arbitrator finds that either the
        substance of Your claim or the relief sought in the Demand is frivolous
        or brought for an improper purpose (as measured by the standards set
        forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
        such fees will be governed by the AAA Rules. In such case, you agree to
        reimburse Us for all monies previously disbursed by it that are
        otherwise your obligation to pay under the AAA Rules. In addition, if
        you initiate an arbitration in which you seek more than $75,000 in
        damages, the payment of these fees will be governed by the AAA rules. An
        award may be entered against a party who fails to appear at a duly
        noticed hearing.
      </p>
      <p>
        If, after finding in a prevailing party’s favor in any respect on the
        merits of prevailing party’s claim, the arbitrator issues an award that
        is greater than the value of the non-prevailing party’s last written
        settlement offer made before an arbitrator was selected, then the
        non-prevailing party will:
      </p>

      <li>
        pay you either the amount of the award or $1,000 (“the alternative
        payment”), whichever is greater; and
      </li>
      <li>
        pay the prevailing party’s attorney, if any, the amount of attorney’s
        fees, and reimburse any expenses (including expert witness fees and
        costs), that attorney reasonably accrues for investigation, preparing,
        and pursuing the claim in arbitration (the “attorney’s fees”).
      </li>
      <br />

      <p>
        The arbitrator may make rulings and resolve disputes as to the payment
        and reimbursement of fees, expenses, and the alternative payment and the
        attorney’s fees at any time during the proceeding and upon request from
        either party made within 14 days of the arbitrator’s ruling on the
        merits.
      </p>
      <p>
        The arbitrator may award injunctive relief only in favor of the
        individual party seeking relief and only to the extent necessary to
        provide relief warranted by that party’s individual claim.
      </p>
      <br />

      <h6>
        <strong>
          YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
          YOUR OR ITS INDIVIDUAL CAPACITIES AND NOT AS PLAINTIFFS OR CLASS
          MEMBERS IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING OR IN THE
          CAPACITY OF A PRIVATE ATTORNEY GENERAL.
        </strong>
      </h6>
      <br />

      <p>
        The arbitrator shall not have the power to commit errors of law or legal
        reasoning, and the parties agree that any injunctive award may be
        vacated or corrected on appeal by either party to a court of competent
        jurisdiction for any such error. Each party will bear its own costs and
        fees on any such appeal. The arbitrator shall not award relief in excess
        of what these Terms provide or award punitive damages or any other
        damages not measured by actual damages. Further, unless both You and We
        agree otherwise, the arbitrator may not consolidate more than one
        person’s claims, and may not otherwise preside over any form of a
        representative or class proceeding.
      </p>
      <p>
        All aspects of the arbitration proceeding, and any ruling, decision or
        award by the arbitrator, will be strictly confidential, other than as
        part of an appeal to a court of competent jurisdiction.
      </p>
      <br />

      <h4>For European Union (EU) Users</h4>
      <p>
        If You are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </p>
      <br />

      <h3>United States Legal Compliance</h3>
      <p>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a “terrorist supporting”
        country, and (ii) You are not listed on any United States government
        list of prohibited or restricted parties.
      </p>
      <br />

      <h3>Notice for California Users</h3>
      <p>
        Users of the Service from California are provided this consumer rights
        notice under Cal. Civ. Code § 1789.3: The Complaint Assistance Unit of
        the Division of Consumer Services of the California Department of
        Consumer Affairs may be contacted in writing at 1625 North Market Blvd.,
        Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or
        (800) 952-5210. You may contact us at info@bumpboxes.com.
      </p>

      <p>
        Residents of California may have certain rights under the California
        Consumer Protection Act. See our Privacy Policy.
      </p>
      <br />

      <h3>Severability and Waiver</h3>
      <br />

      <h4>Severability</h4>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>

      <h4>Waiver</h4>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Terms shall not effect a party`s
        ability to exercise such right or require such performance at any time
        thereafter nor shall be the waiver of a breach constitute a waiver of
        any subsequent breach.
      </p>
      <br />

      <h3>Translation Interpretation</h3>
      <br />
      <p>
        These Terms and Conditions may have been translated if We have made them
        available to You on our Service. You agree that the original English
        text shall prevail in the case of a dispute.
      </p>
      <br />

      <h3>Changes to These Terms and Conditions</h3>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least seven days’ notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </p>

      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>
      <br />

      <h3>Terms for Our Durable Medical Equipment Customers</h3>
      <p>
        The following terms and conditions, along with our other terms and
        conditions, apply to our customers who are interested in, request,
        order, or otherwise engage with us as it relates to durable medical
        equipment such as, but not limited to, breast pumps.
      </p>

      <h4>Authorization/Consent for Care/Service</h4>
      <p>
        The patient or representative signing below has been informed of the
        home care treatment and product options available to them and of the
        selection of providers from which the patient may choose. The patient
        further authorizes us under the direction of the patient`s prescribing
        physician, to provide home medical equipment, supplies and services
        either directly or through our affiliated companies. The patient has
        acknowledged that the Client/Patient Service Agreement has been
        explained and that the patient understands the information.
      </p>

      <h4>Advanced Directives (Appendix A)</h4>
      <p>
        You understand your right to formulate and to issue Advance Directives
        to be followed should they become incapacitated.
      </p>

      <h4>Assignment of Benefits/Authorization for Payment</h4>
      <p>
        All benefits and payments must be made directly to us or our designated
        affiliated companies furnished home medical equipment, products and
        services. We, or our affiliated companies, will seek such benefits and
        payments on the patient`s behalf. It is understood that, as a courtesy,
        We, or our affiliated companies, will bill Medicare/Medicaid or other
        federally funded sources and other payers and insurer(s) providing
        coverage, with a copy to us. The patient is responsible for providing
        all necessary information and for making sure all certification and
        enrollment requirements are fulfilled. Any changes in insurance coverage
        must be reported to us within 10 days of the change.
      </p>

      <h4>Release of Information</h4>
      <p>
        The patient or representative requests and authorizes us, the
        prescribing physician, hospital, and any other holder of information
        relevant to service or equipment provided by us, or our affiliated
        companies, to release information upon request, to us, our affiliated
        companies, any payer source, physician, or any other medical personnel
        or agency involved with service. The patient also authorizes us and our
        affiliated companies to review medical history and payer information for
        the purpose of providing treatment, equipment or products.
      </p>

      <h4>Financial Responsibility, Arrangements, and Health Insurance</h4>
      <p>
        All payment and all sums that may become due for the services or
        products provided are due at the time services are rendered unless
        payment arrangements have been approved in advance by our staff. These
        sums include, but are not limited to, all deductibles, co-payments,
        out-of-pocket requirements, and non-covered services. If for any reason
        and to any extent, we do not receive payment from the patient`s payer
        source because the patient is no longer eligible for coverage or because
        the service or product is not covered, the patient`s balance will be due
        in full, within 30 days of receipt of invoice. We cash, checks, & most
        major credit cards. Payments by check may be converted into electronic
        fund transfers and funds may be debited from your account as soon as the
        same day payment is received.
      </p>

      <p>
        All patient owed charges not paid within 30 days of billing date shall
        be assessed late charges and are subject to legally allowable interest
        charges. In such an event, the patient will be liable for all charges,
        including collection costs and all attorneys` fees, as applicable.
        Balances older than 90 days may be subject to additional collection fees
        and interest charges of 1.5% per month. We realize that temporary
        financial problems may affect timely payment to your account. If such
        problems do arise, we encourage you to contact us promptly for
        assistance in the management of your account.
      </p>

      <p>
        You understand if you have an unpaid balance to us and do not make
        satisfactory payment arrangements, my account may be placed with an
        external collection agency.
      </p>

      <p>
        In order for us or their designated external collection agency to
        service my account, and where not prohibited by applicable law, you
        agree that we and the designated external collection agency are
        authorized to (i) contact you by telephone at the telephone number(s)
        you are providing, including wireless telephone numbers, which could
        result in charges to you, (ii) contact you by sending text messages
        (message and data rates may apply) or emails, using any email address
        you provide and (iii) methods of contact may include using
        pre-recorded/artificial voice message and/or use of an automatic dialing
        device, as applicable. Furthermore, you consent the designated external
        collection agency to share personal contact and account related
        information with third party vendors to communicate account related
        information via telephone, text, e-mail, and mail notification.
      </p>

      <h4>Financial Responsibility for Non-Covered Items</h4>
      <p>
        By accepting these Terms and Conditions, you agree that if your
        insurance does not cover all items ordered, even if the item is one that
        the patient or the healthcare provider has good reason to think is
        necessary, we will not charge the insurance company and you will have
        financial responsibility for payment for the non-covered item(s). You
        also agree that we have offered alternative covered items (if any) and
        the cost of the non-covered item, and that you have then accepted
        financial responsibility for the non-covered item.{' '}
      </p>

      <h4>Intent, Previous Receipt of Breast Pump</h4>
      <p>
        By agreeing to these terms and conditions, you are also attesting that
        you are pregnant, you desire to order a breast pump, and you have not
        already ordered or received an electric breast pump for this pregnancy.
      </p>

      <h4>Insurance Upgrade Breast Pumps</h4>
      <p>
        If you chose to order a breast pump designated as an `Insurance Upgrade
        Option` you have elected to order a breast pump that is not covered 100%
        by your insurance. You will be required to pay the difference
        out-of-pocket. After your insurance benefit has been applied you will be
        responsible for the out-of-pocket amount you authorized today at time of
        checkout. By checking the terms and conditions box you will be
        acknowledging that you had the option of selecting a breast pump 100%
        covered by your insurance, but you chose the one that required an
        additional upcharge.
      </p>

      <h4>Returned Goods</h4>
      <p>
        Due to Federal and State Pharmacy Regulations ancillary items prescribed
        for home health care cannot be re-dispensed and cannot be returned for
        credit. Medical equipment cannot be returned. Sale items cannot be
        returned.
      </p>

      <h4>Consent for Contact</h4>
      <p>
        By signing and submitting this form, you or your or representative
        consent to receive phone calls, texts, e-mails, and pre-recorded
        messages from us or any of our subsidiaries regarding our products and
        services, at the phone number(s) or email address provided; including
        wireless number if provided. These calls may be generated using an
        automated technology and normal carrier charges may apply.
      </p>

      <p>
        Please be aware that most standard email is not a secure means of
        communication and your protected health information that may be
        contained in our emails to you will not be encrypted. This means that
        there is risk that your protected health information in the emails could
        be intercepted and read by, or disclosed to, unauthorized third parties.
        Use of alternative and more secure methods of communication with us,
        such as telephone, fax or the U.S. Postal Service are available to you.
        If you do not wish to accept the risks associated with non-secure
        unencrypted email communications from us containing your protected
        health information, please indicate that you do not wish to receive such
        emails from us by contacting us at 888-913-7879. If you agree to receive
        information from us via email or text, you agree to accept the security
        and privacy risks of this type of communication.
      </p>

      <p>
        You agree that if you consent to SMS notifications regarding your order,
        text alerts will be sent to the number you provide. You understand that
        anyone with access to the mobile phone or carrier account associated
        with the number you have provided will be able to see this information.
      </p>

      <h4>Information for Medicare Patients</h4>
      <p>
        The products and/or services provided to the patient by us are subject
        to the supplier standards contained in the federal regulations shown at
        42 Code of Federal Regulations Section 424.57(c). These standards
        concern business professional and operational matters (e.g., honoring
        warranties and hours of operation).The full text of these standards can
        be obtained at
        <a href='https://ecfr.gpoaccess.gov'>http://ecfr.gpoaccess.gov.</a>
        Upon request we will furnish you a written copy of the standards.
      </p>

      <h4>On-Call/After Hours Service</h4>
      <p>
        We maintain 24-hour availability through our main telephone
        (866-418-8695). This on-call service is free to you. Should a
        life-threatening medical emergency arise, the patient or caregiver
        should contact their local emergency services number immediately for
        assistance (usually 911).
      </p>

      <h4>Patient Complaint/Grievance Process</h4>
      <p>
        In the event you should become dissatisfied with any portion of their
        our provided home care experience, a complaint may be lodged with us
        without concern for reprisal, discrimination, or unreasonable
        interruption of service. The patient has the right to present questions
        or grievances to one of our staff members and to receive a response in a
        reasonable period of time. For concerns regarding quality of care or
        other services, please contact our office by phone or mail. Grievances
        can also be reported to Medicare, or Bump Health Inc.`s Medicare
        accreditation agency, <a href='https://www.bocusa.org/'>BOC.</a> All
        contact information and our process for handling complaints can be found
        below.
      </p>

      <p>
        The following procedure details the steps that Bump Health Inc. will
        take when a your complaint/grievance is received.
      </p>
      <p>
        After receiving the concern, the our Customer Service Supervisor will
        take the following steps:
      </p>

      <ol>
        <li>
          Contact the person making the concern within a reasonable amount of
          time, attempting to do so within 5 days, if contact has not already
          been established.
        </li>
        <li>
          Determine what actions you feel should be initiated regarding the
          concern.
        </li>
        <li>
          Speak with involved employees and conduct additional training as
          appropriate.
        </li>
        <li> Attempt to resolve the concern to your satisfaction.</li>
        <li>
          Report status of activities to you as soon as possible, aiming for
          within two business days following receipt of concern.
        </li>
        <li>
          Send complaint information to our compliance staff so they can record
          it.
        </li>
        <li>
          If the complaint remains unresolved, we will have a department
          supervisor contact you as soon as possible, with the aim of doing so
          within 5 working days.
        </li>
        <li>
          Within a commercially reasonable amount of time, aiming for within 14
          days, we will provide written notification to you of the results of
          the investigation.
        </li>
      </ol>

      <p>
        Patient Satisfaction Surveys: We may send patient surveys via email
        approximately 5-7 days after your order has been shipped. Data
        collected/analyzed on a weekly basis. By signing below, you acknowledge
        that you have been informed of this patient satisfaction survey
        procedure.
      </p>

      <h3>Appendix A</h3>
      <br />
      <h4>Advanced Directives</h4>
      <p>
        What is an Advance Directive? An advance directive is a set of
        directions you give about the medical and mental health care you want if
        you ever lose the ability to make decisions for yourself. There are two
        ways for you to make a formal advance directive. These include: Living
        Wills and Healthcare Powers of Attorney. Forms & additional information
        may be obtained from the Secretary of State website.
      </p>

      <p>
        Do I have to have an Advance Directive and what happens if I don`t?
        Making an advance directive is your choice. If you become unable to make
        your own decisions, and you have no advance directive, your physician or
        mental health care provider will consult with someone close to you about
        your care. Discussing your wishes with your family and friends now will
        help ensure that you get the level of treatment you want when you can no
        longer tell your health providers what you want.
      </p>

      <p>
        What is a Living Will? A Living Will is a legal document that tells
        others that you want to die a natural death if you: become incurably
        sick with an irreversible condition that will result in your death
        within a short period of time; are unconscious and your physician
        determines that it is highly unlikely you will regain consciousness; or
        have advanced dementia or a similar condition which results In a
        substantial cognitive loss and it is highly unlikely the condition can
        be reversed. You can direct your physician not to use certain
        life-prolonging treatments such as a breathing machine (“respirator“ or
        “ventilator“), or to stop giving you food and water through a tube
        (“artificial nutrition or hydration“ through feeding tubes and IVs). The
        document goes into effect only when your physician and one other
        physician determine that you meet one of the conditions specified in the
        Living Will. You can cancel anytime by communicating your intent to
        cancel it in any way.
      </p>

      <p>
        What is a Healthcare Power of Attorney? A Healthcare Power of Attorney
        is a legal document in which you can name a person(s) as your health
        care agent(s) to make medical and mental health decisions for you if you
        become unable to decide for yourself. You can say which treatments you
        would want and not want. You should choose an adult you trust to be your
        health care agent. Discuss your wishes with that person(s) before you
        put them in writing. The document will go into effect when a physician
        states in writing that you are not able to make or to communicate your
        health care choices. You can cancel or change while you are able to make
        and communicate your decisions.{' '}
      </p>

      <p>
        How do I make an Advance Directive? There are several rules to protect
        you and ensure your wishes are clear to the physician who may be asked
        to carry them out. An advanced directive must be: (1) written; (2)
        signed by you while you are still able to make and communicate health
        care decisions; (3) witnessed by two qualified adults; and (4)
        notarized. A qualified witness is a competent adult who sees you sign,
        is not a relative, and will not inherit anything from you upon your
        death. The witness cannot be your physician, a licensed employee of your
        physician or any paid employee of a healthcare facility where you live
        or that is treating you.
      </p>

      <p>
        Who should I talk to about an Advance Directive? You should talk to
        those closest to you about an advance directive and your feelings about
        the health care you would like to receive. Your physician or health care
        provider can answer medical questions. A lawyer can answer questions
        about the law. Give copies to your family, your physician or mental
        health providers, your health care agent(s), and any family members or
        close friends who might be asked about your care should you become
        unable to make decisions. Please furnish Bump Health Inc. with a copy of
        your advance directives.
      </p>

      <h3>Appendix B</h3>
      <br />
      <h4>Infection Control</h4>

      <p>
        It is our policy to conform to the acceptable standards of infection
        control pertaining to equipment and home health services issued by the
        Centers for Disease Control (CDC) and the Occupational Safety and Health
        Administration (OSHA), in order to ensure the safety of clients/patients
        and employees, and also to ensure quality client/patient service.
      </p>

      <p>
        How infections occur and are spread: An infection occurs when germs
        enter the body, increase in number, and cause a reaction of the body.
      </p>

      <p> Three things are necessary for an infection to occur:</p>
      <ul>
        <li>
          Source: Places where infectious agents (germs) live (e.g., sinks,
          surfaces, human skin)
        </li>
        <li>Susceptible Person with a way for germs to enter the body</li>
        <li>Transmission: a way germs are moved to the susceptible person</li>
      </ul>

      <p>
        There are a few general ways that germs travel in healthcare settings:
      </p>

      <ul>
        <li>Through contact (i.e., touching)</li>
        <li>Sprays and splashes (when an infected person coughs or sneezes)</li>
        <li>Inhalation (when germs are aerosolized in tiny particle)</li>
        <li>
          Sharp injuries (i.e., when someone is accidentally stuck with a used
          needle or sharp instrument).
        </li>
      </ul>

      <p> How to prevent infection:</p>
      <ul>
        <li>Wash your hands often</li>
        <li>Get vaccinated</li>
        <li>Get vaccinated and use antibiotics sensibly</li>
        <li>Stay at home if you have signs and symptoms of an infection.</li>
        <li>Cover your mouth and nose</li>
        <li>
          Disinfect the `hot zones` in your residence - the kitchen and bathroom
        </li>
        <li>Don`t share personal items</li>
        <li>
          Make sure health care providers clean their hands or wear gloves
        </li>
        <li>Clean equipment and supplies regularly</li>
        <li>
          Replace equipment on a regular schedule. Contact Bump Health Inc.
          (888-345-1780) when your supplies are to be thrown out.{' '}
        </li>
      </ul>

      <p>
        Symptoms of Hepatitis infection: Extreme fatigue, mild fever, headache,
        loss of appetite, nausea, and vomiting. Symptoms of Tuberculosis (TB)
        infection: fatigue, anorexia, productive cough, coughing up blood,
        weight loss, loss of appetite, lethargy, weakness, night sweats, chills,
        flu-like symptoms and fever. Some people with TB may show no symptoms.
        NOTIFY YOUR HEALTHCARE PROVIDER IF YOU FEEL YOU HAVE BECOME INFECTED.
      </p>

      <h3>Appendix C </h3>
      <br />
      <h4>Client/Patient Bill of Rights & Responsibilities</h4>
      <p> You have the right to:</p>
      <ol>
        <li>
          Receive reasonable coordination and continuity of services from the
          referring agency for home medical equipment services
        </li>
        <li>
          Receive a timely response from Bump Health Inc. when services/care are
          needed or requested
        </li>
        <li>
          Be fully informed in advance about service/care to be provided,
          including the disciplines that furnish care and the frequency of
          visits, as well as any modifications to the Plan of Care
        </li>
        <li>
          Participate in the development and periodic revision of the Plan of
          Service or the Plan of Care
        </li>
        <li>
          Informed consent and refusal of services, care or treatment after the
          consequences of refusing services, care or treatment are fully
          presented
        </li>
        <li>
          Be informed in advance of the charges, including payment for service
          or care expected from third parties and any charges for which the
          client/patient will be responsible
        </li>
        <li>
          Have one`s property and person treated with respect, consideration,
          and recognition of client/patient dignity and individuality
        </li>
        <li>
          Be able to identify visiting staff members through proper
          identification
        </li>
        <li>
          Voice grievances/complaints regarding treatment of care or lack of
          respect of property, or recommend changes in policy, staff or
          service/care without restraint, interference, coercion, discrimination
          or reprisal
        </li>
        <li>
          Have grievances/complaints regarding treatment or care that is (or
          fails to be) furnished, or lack of respect of property investigated
        </li>
        <li>
          Choose a health care provider and have access to information regarding
          provider`s work history and training
        </li>
        <li>
          Confidentiality and privacy of all information contained in the
          client/patient record and of Protected Health Information
        </li>
        <li>
          Receive appropriate service/care without discrimination in accordance
          with physician orders
        </li>
        <li>
          Be informed of any financial benefits when referred to an organization
        </li>
        <li>
          Be informed in advance of care/service being provided and their
          financial responsibility
        </li>
        <li>
          Be fully informed of one`s responsibilities and Bump Health Inc.`s
          policies regarding patient responsibilities
        </li>
        <li>
          Be informed of client/patient rights under state law to formulate
          advance care directives
        </li>
        <li>
          Be informed of anticipated outcomes of service or care and of any
          barriers in outcome achievement
        </li>
        <li>Be informed of Bump Health Inc.`s on-call service.</li>
        <li>
          Be informed of Bump Health Inc.`s patient satisfaction survey process.
        </li>
        <li>Be informed of supervisory accessibility and availability.</li>
        <li>
          Fair treatment, regardless of race, ethnicity, creed, religious
          belief, sexual orientation, gender, age, health status, or source of
          payment for care.
        </li>
        <li>
          Be advised on Bump Health Inc.`s policies and procedures regarding the
          disclosure of clinical records, clinical guidelines, and management of
          care
        </li>
        <li>Be advised of Bump Health Inc.`s procedures for discharge.</li>
        <li>Report fraud, waste, or abuse</li>
        <li>
          Be notified within 10 days if Bump Health Inc.`s license is revoked,
          suspended, canceled, annulled, withdrawn, recalled, or amended.
        </li>
        <li>
          Know of their rights and responsibilities in the treatment process
          (and the laws that relate to them), and to make recommendations
          regarding the organization`s rights and responsibilities policy.
        </li>
        <li>
          Be informed about advocacy and community groups and prevention
          services.
        </li>
        <li>Access care easily and in a timely fashion.</li>
        <li>
          Candid discussion about all their treatment choices, regardless of
          cost or coverage by their benefit plan.
        </li>
        <li>The delivery of services in a culturally competent manner.</li>
        <li>
          Receive information about the scope of services that the organization
          will provide and specific limitations on those services.
        </li>
        <li>
          Be free from mistreatment, neglect, or verbal, mental, sexual, and
          physical abuse, including injuries of an unknown source, and
          misappropriation of client/patient property.
        </li>
      </ol>
      <br />
      <h4>Client/Patient has the responsibility to:</h4>
      <br />
      <ol>
        <li>
          Client agrees that rental equipment will be used with reasonable care,
          not altered or modified, and returned in good condition (normal wear
          and tear excepted).
        </li>
        <li>
          Client agrees to promptly report to Bump Health Inc. any malfunctions
          or defects in rental equipment so that repair/ replacement can be
          arranged.
        </li>
        <li>
          Client agrees to provide Bump Health Inc. access to all rental
          equipment for repair/replacement, maintenance, and/or pick-up of the
          equipment.
        </li>
        <li>
          Client agrees to use the equipment for the purposes so indicated and
          in compliance with the physician`s prescription.
        </li>
        <li>
          Client agrees to keep the equipment in their possession and at the
          address, to which it was delivered unless otherwise authorized by Bump
          Health Inc.
        </li>
        <li>
          Client agrees to notify Bump Health Inc. of any hospitalization,
          change in customer insurance, address, telephone number, physician, or
          when the medical need for the rental equipment no longer exists.
        </li>
        <li>
          Client agrees to request payment of authorized Medicare, Medicaid, or
          other private insurance benefits are paid directly to Bump Health Inc.
          for any services furnished by Bump Health Inc.
        </li>
        <li>
          Client agrees to accept all financial responsibility for home medical
          equipment furnished by Bump Health Inc.
        </li>
        <li>
          Client agrees to pay for the replacement cost of any equipment
          damaged, destroyed, or lost due to misuse, abuse or neglect.
        </li>
        <li>
          Client agrees not to modify the rental equipment without the prior
          consent of Bump Health Inc.
        </li>
        <li>
          Client agrees that any authorized modification shall belong to the
          titleholder of the equipment unless equipment is purchased and paid
          for in full.
        </li>
        <li>
          Client agrees that title to the rental equipment and all parts shall
          remain with Bump Health Inc. at all times unless equipment is
          purchased and paid for in full.
        </li>
        <li>
          Client agrees that Bump Health Inc. shall not insure or be responsible
          to the client for any personal injury or property damage related to
          any equipment; including that caused by use or improper functioning of
          the equipment; the act or omission of any other third party, or by any
          criminal act or activity, war, riot, insurrection, fire or act of God
        </li>
        <li>
          Client understands that Bump Health Inc. retains the right to refuse
          delivery of service to any client at any time.
        </li>
        <li>
          Client agrees that any legal fees resulting from a disagreement
          between the parties shall be borne by the unsuccessful party in any
          legal action taken.
        </li>
        <li>
          Patients/Clients have the responsibility to give providers the
          information they need, in order to provide the best possible care and
          to ask questions about their care.
        </li>
        <li>
          Clients have the responsibility to treat those giving them care with
          dignity and respect and not to take actions that could harm others.
        </li>
        <li>
          Patients/Clients have the responsibility to understand and help
          develop and follow the agreed-upon treatment plans for their care,
          including the agreed-upon medication plan and to let the provider know
          when the treatment plan no longer works for them.
        </li>
        <li>
          Patients/Clients have the responsibility to keep their appointments.
          Patients should call their providers as soon as possible if they need
          to cancel visits.
        </li>
        <li>
          Patients/Clients have the responsibility to let their provider know
          about any changes to their contact information (name, address, phone,
          etc.) and insurance coverage.
        </li>
        <li>
          Patients/Clients have the responsibility to tell their provider about
          medication changes, including medications given to them by others.
        </li>
        <li>
          Patients/Clients have the responsibility to let their provider know
          about problems with paying fees.
        </li>
      </ol>
      <br />
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms and Conditions, You can
        contact us:
      </p>
      <li>By email: info@bumpboxes.com</li>
    </Col>
  </Row>
);

export default TermsText;
