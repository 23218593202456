import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import VisibilitySensor from 'react-visibility-sensor';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import {
  SelectSubscriptionBoxes,
  SubscriptionSignupHeader,
  Testimonials,
  HomepageManifesto,
  GetTheBox,
  HowItWorks,
  SocialProof,
  PastSubs,
  VisibilityPlaceholder,
  Loading,
} from '../../components';
import AsSeenIn from '../../components/asseenin';
import Hero from '../../components/hero';
import BitsyModal from '../../components/bitsy-modal';
import SchemaData from './SchemaData';
import './style.css';

// Services
import Environment from '../../services/Environment';
import CartUtils from '../../services/CartUtils';
import generalHelpers from '../../services/generalHelpers';
import Auth from '../../services/Auth';
import BitsyApollo from '../../services/BitsyApollo';
import Features from '../../services/Features';

// Constants
import Content from '../../constants/Content';

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      provider: Environment.store,
      showMonthlyPlans: true,
      showSocialProof: false,
      showTestimonialsPlus: false,
      showPastSubs: false,
      showHowItWorks: false,
      showSelectSubs: false,
      modalType: 'NONE',
      modalHeader: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params && this.props.match.params.referralCode) {
      localStorage.setItem(
        'referralCode',
        this.props.match.params.referralCode,
      );
      this.setState({ referralCode: this.props.match.params.referralCode });
    }
    if (this.props.location?.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      if (searchParams.get('toggle') === 'b') {
        this.setState({ showMonthlyPlans: false });
      }
    }
    const hashString = this.props.location?.hash?.slice(1);
    if (hashString === 'how-it-works' || hashString === 'past-boxes') {
      this.setState({ showHowItWorks: true, showPastSubs: true });
    }
  }

  goToCart = () => {
    generalHelpers.goToCart(this.props);
  };

  handleAddPreCheckoutPromo = async (res) => {
    if (res) {
      this.setState(res);
      this.goToCart();
    }
  };

  nextStep = async (plan, subscriptionCouponFromURL) => {
    this.setState({ isLoading: true });
    const res = await CartUtils.addSubscriptionToCart(
      plan,
      this.state.provider,
      null,
      subscriptionCouponFromURL,
    );
    if (res) {
      const isFeatureGateOn = Features.featureIsEnabled(
        '2024-enable_new2024checkout',
      );
      const noProductsInCart = !res.productsAndDetails?.length;
      const onlyGWPProductsInCart = res.productsAndDetails?.every(
        (product) => product.isAddedCouponProduct,
      );
      const noInvalidProductsInCart = noProductsInCart || onlyGWPProductsInCart;
      if (isFeatureGateOn && noInvalidProductsInCart) {
        if (!Auth.hasToken()) {
          await BitsyApollo.getEmptyUser();
        }
        const initCartInfo = await CartUtils.getCart();
        this.props.history.push({
          pathname: '/checkout/start-membership',
          state: { initCartInfo },
        });
      } else {
        this.setState({ ...res, modalType: 'PRE-CHECKOUT PROMO' });
      }
    }
  };

  showFreeShippingDetails = () => {
    this.setState({ modalType: 'FREE SHIPPING DETAILS' });
  };

  renderModal = () => {
    if (this.state.modalType === 'FREE SHIPPING DETAILS') {
      return (
        <BitsyModal
          isShowingModal
          content='freeShippingDetails'
          onExit={this.exitModal}
          type='bodyAndExitModal'
          h2ClassName='smsOptInTitle'
          modalHeaderClass='smsOptInModalHeader'
          data={{}}
        />
      );
    }
    return null;
  };

  exitModal = () => {
    this.setState({ modalType: 'NONE' });
  };

  viewMonthlyPlans = () => {
    this.setState({ showMonthlyPlans: true });
  };

  viewBundledPlans = () => {
    this.setState({ showMonthlyPlans: false });
  };

  getTheBoxCtaClicked = () => {
    this.props.history.push('/gift');
  };

  heroSubscriptionCtaClicked = () => {
    this.scrollToSubscriptionSelect();
  };

  handleSecondHeroButtonClick = () => {
    this.props.history.push('/gift');
  };

  howItWorksCtaClicked = () => {
    this.scrollToSubscriptionSelect();
  };

  scrollToSubscriptionSelect = () => {
    const subscriptionRow = document.getElementById(
      'homepageSubscriptionSection',
    );

    if (subscriptionRow) {
      subscriptionRow.scrollIntoView();
    }
  };

  onVisibilityChange = (stateKey, isVisible) => {
    if (isVisible && !this.state[stateKey]) {
      this.setState({
        [stateKey]: true,
      });
    }
  };

  onVisibilitySocialProof = (isVisible) => {
    this.onVisibilityChange('showSocialProof', isVisible);
  };

  onVisibilityTestimonialsPlus = (isVisible) => {
    this.onVisibilityChange('showTestimonialsPlus', isVisible);
  };

  onVisibilityPastSubs = (isVisible) => {
    this.onVisibilityChange('showPastSubs', isVisible);
  };

  onVisibilityHowItWorks = (isVisible) => {
    this.onVisibilityChange('showHowItWorks', isVisible);
  };

  onVisibilitySelectSubs = (isVisible) => {
    this.onVisibilityChange('showSelectSubs', isVisible);
  };

  updateTitle = (title) => {
    if (title) {
      this.setState({ modalHeader: title });
    }
  };

  renderLoading = () => {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return null;
  };

  render() {
    return (
      <Container className='mainPageContainer'>
        <Helmet>
          {/* Empty Helmet tag will inherit from the main app defaults. */}
        </Helmet>
        {this.renderLoading()}
        <Hero
          title={Content.home.title}
          subheading={Content.home.description}
          buttonText='SUBSCRIBE'
          handleScrollToElement={this.heroSubscriptionCtaClicked}
          defaultImage={`/hero.${Environment.store}.v1.jpg`}
          layout='left'
          stars
          secondButtonText='Give A Gift'
          handleSecondHeroButtonClick={this.handleSecondHeroButtonClick}
        />
        <AsSeenIn />

        <Row
          id='homepageSubscriptionSection'
          className='selectSubscriptionContainer'
        >
          <Col>
            <VisibilitySensor
              offset={{ top: 25 }}
              partialVisibility
              onChange={this.onVisibilitySelectSubs}
              active={!this.state.showSelectSubs}
            >
              {this.state.showSelectSubs ? (
                <>
                  <SubscriptionSignupHeader
                    viewMonthlyPlans={this.viewMonthlyPlans}
                    viewBundledPlans={this.viewBundledPlans}
                    title={Content.subscriptionPlanSelection.title}
                    subtitle={Content.subscriptionPlanSelection.description}
                    showMonthlyPlans={this.state.showMonthlyPlans}
                    fullBackgroundColor={false}
                    subBoxContainerId='sub-wrapper'
                  />
                  <SelectSubscriptionBoxes
                    referralCode={this.state.referralCode}
                    showMonthlyPlans={this.state.showMonthlyPlans}
                    nextStep={this.nextStep}
                    subBoxContainerId='sub-wrapper'
                    handleAddPreCheckoutPromo={this.handleAddPreCheckoutPromo}
                    updateTitle={this.updateTitle}
                    modalTitle={this.state.modalHeader}
                    modalType={this.state.modalType}
                    goToCart={this.goToCart}
                    planFilter={
                      window.location?.search
                        ? new URLSearchParams(window.location.search).get(
                            'plans',
                          )
                        : ''
                    }
                    matchParams={this.props.match?.params}
                  />
                </>
              ) : (
                <VisibilityPlaceholder />
              )}
            </VisibilitySensor>
          </Col>
        </Row>
        <Row className='freeShippingTextContainer'>
          {this.renderModal()}
          <Col>
            FREE SHIPPING & RETURNS ON ALL SUBSCRIPTIONS
            <Button
              className='infoPopupButton'
              variant='incognito'
              onClick={this.showFreeShippingDetails}
            >
              <FontAwesomeIcon icon={faCircleInfo} size='lg' />
            </Button>
          </Col>
        </Row>

        <VisibilitySensor
          offset={{ top: 50 }}
          partialVisibility
          onChange={this.onVisibilityPastSubs}
          active={!this.state.showPastSubs}
        >
          {this.state.showPastSubs ? <PastSubs /> : <VisibilityPlaceholder />}
        </VisibilitySensor>

        <VisibilitySensor
          offset={{ top: 50 }}
          partialVisibility
          onChange={this.onVisibilityHowItWorks}
          active={!this.state.showHowItWorks}
        >
          {this.state.showHowItWorks ? (
            <HowItWorks
              handleHowItWorksCtaClicked={this.howItWorksCtaClicked}
            />
          ) : (
            <VisibilityPlaceholder />
          )}
        </VisibilitySensor>

        <VisibilitySensor
          offset={{ top: 50 }}
          partialVisibility
          onChange={this.onVisibilityTestimonialsPlus}
          active={!this.state.showTestimonialsPlus}
        >
          {this.state.showTestimonialsPlus ? (
            <>
              <Testimonials
                title={Content.testimonials.title}
                cards={Content.testimonials.cards}
              />
              {Environment.store === 'bump' ? (
                <HomepageManifesto
                  getTheBoxCtaClicked={this.getTheBoxCtaClicked}
                />
              ) : null}
              <GetTheBox getTheBoxCtaClicked={this.getTheBoxCtaClicked} />
            </>
          ) : (
            <VisibilityPlaceholder />
          )}
        </VisibilitySensor>

        <VisibilitySensor
          offset={{ top: 50 }}
          partialVisibility
          onChange={this.onVisibilitySocialProof}
          active={!this.state.showSocialProof}
        >
          {this.state.showSocialProof ? (
            <SocialProof />
          ) : (
            <VisibilityPlaceholder />
          )}
        </VisibilitySensor>

        <SchemaData />
      </Container>
    );
  }
}

export default withRouter(Home);
